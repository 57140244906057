import React, { useState } from 'react';

import iconPlus from '../assets/images/svg/plus.svg';
import iconMinus from '../assets/images/svg/minus.svg';

// TODO: LOCALIZE FAQS

const Faq = ({ title, description }) => {
  const [faqOpen, setFaqOpen] = useState(false);

  return (
    <div className={`c-faq ${!faqOpen ? '' : 'c-faq--open'}`} onClick={() => setFaqOpen(!faqOpen)}>
      <h3 className="c-text-16 c-faq__title">
        {title}{' '}
        <div className="c-faq__cross">
          <img src={!faqOpen ? iconPlus : iconMinus} alt="" />
        </div>
      </h3>
      <div
        className="c-text-14 c-faq__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default Faq;
